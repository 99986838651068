import React, { useEffect, useRef } from 'react';
import MessageList from './MessageList';
import { MessageDto } from '../models/MessageDto';
import { Theme } from '../components/colorScheme';

// Updated to expect userId as a required prop for user-specific logic
interface ChatProps {
  messages: MessageDto[];
  theme: Theme;
  userId: string; // Now expected as a required prop
}

const Chat: React.FC<ChatProps> = ({ messages, theme, userId }) => {
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    // Placeholder for any immediate use of userId, such as fetching messages
  }, [messages, userId]); // Add userId as a dependency if its value influences the effect

  return (
    <div className="chat-container" style={{
      color: theme.textColor,
      background: theme.chatBackgroundColor, // Use chatBackgroundColor directly
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <MessageList messages={messages} theme={theme} />
      <div ref={endOfMessagesRef} />
    </div>
  );
} 

export default Chat;